<template>
  <div id="site-list">
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="8"
        style="margin-right:auto"
        class="d-flex"
        align="left"
      >
        <v-select
          v-model="filters.channel_id"
          label="Show Channel"
          :items="channelOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
        ></v-select>

        <v-btn
          color="primary"
          class="ml-2"
          :loading="loading"
          :disabled="loading"
          @click="loadData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            <!-- {{ $t('common.list.refresh') }} -->
            Refresh
          </span>
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        sm="4"
        style="margin-right:auto"
        align="right"
      >
        <!-- <v-btn
          color="primary"
          @click="setAside(null)"
        >
          Create
        </v-btn> -->
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <!-- key -->
        <template #[`item.key`]="{ item }">
          <router-link
            class="text-no-wrap"
            :to="{ name: 'selection-view', params: { id: item.id } }"
          >
            {{ item.key }} <span v-if="item.stats.item_count">({{ item.stats.item_count }})</span>
          </router-link>
          <div
            v-for="item in item.children"
            :key="item.key"
            class="text-wrap dict-value ml-2"
          >
            <router-link
              class="text-no-wrap"
              :to="{ name: 'selection-view', params: { id: item.id } }"
            >
              {{ item.key }} <span v-if="item.stats.item_count">({{ item.stats.item_count }})</span>
            </router-link> ({{ item.distributor.slug }})
          </div>
        </template>

        <!-- channel -->
        <template #[`item.channel_id`]="{ item }">
          <span class="text-wrap">{{ t(item.channel.name) }}</span>
        </template>

        <!-- notes -->
        <template #[`item.notes`]="{ item }">
          <span class="text-wrap">{{ item.notes }}</span>
        </template>

        <!-- active-->
        <template #[`item.active`]="{ item }">
          <span class="text-no-wrap">{{ item.active ? 'Yes' : 'No' }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item, 'parent')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="setAside(item, 'child')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPlus }}
                    </v-icon>
                    <span>Add Alternate Distributor</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  link
                  @click="deleteSelection(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <selection-child-aside
      v-if="aside === 'child'"
      v-model="aside"
      :resource="resource"
      @changed="loadData(); aside = ''"
    />

    <selection-aside
      v-if="aside === 'parent'"
      v-model="aside"
      :resource="resource"
      @changed="loadData(); aside = ''"
    />

    <p>The root selection is applied if the distributor's selection has no item or is missing.</p>
    <p>So you might NOT need to create a selection for every distributor.</p>
  </div>
</template>

<script>
import channelStore from '@/modules/channel/store'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import {
mdiDeleteOutline,
mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import SelectionAside from './selection-resource/SelectionAside.vue'
import SelectionChildAside from './selection-resource/SelectionChildAside.vue'
import useSelectionList from './useSelectionList'

export default {
  components: {
    SelectionAside,
    SelectionChildAside,
  },
  setup() {
    const {
      tableItems, tableColumns, tableTotal, options, filters, loading, loadData, deleteSelection,
    } = useSelectionList({
      query: { root_only: 1 },
    })

    const actions = ['Edit']

    const aside = ref('')
    const resource = ref(null)

    const setAside = (item, type = 'parent') => {
      aside.value = type
      resource.value = item
    }

    // const categories = ref([])

    // const loadCategories = async () => {
    //   try {
    //     const {
    //       status,
    //       data: {
    //         data: { records },
    //       },
    //     } = await fetchDistributors()

    //     if (status === 200) {
    //       categories.value = records.map(record => {
    //         return { title: record.name.en, value: record.id }
    //       })
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    // loadCategories()

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(false)

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      // categories,
      setAside,
      aside,
      resource,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,
      channelOptions: computed(() => channelStore.state.channels.map(ch => ({ title: `${t(ch.name)} (${ch.code})`, value: ch.id }))),

      actions,

      loadData,
      deleteSelection,
      singleSelect,
      headerprops,
      showFilter,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .dict-value {
    max-height: 45px;
    overflow: hidden;
  }
  </style>